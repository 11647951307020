import { motion } from "framer-motion";
import Link from "next/link";
import { useInView } from "react-intersection-observer";
import Block, { BlockContent, BlockGrid } from "~/components/Block";
import ButtonArrow from "~/components/ButtonArrow";
import LinkButton from "~/components/LinkButton";
import { SimpleQuestionList } from "~/components/QuestionList";
import {
  QuestionCategoryDocument,
  QuestionCategoryQuestionLinkedDocument,
} from "~/external/v2/types";

// TODO: Extends getCommonSliceProps
type QuestionCategoryGridProps = {
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  highlightColor?: string;
  color?: string;
  animateIn?: boolean;

  maximumQuestions: number;
  columnsOnMobile: number;
  columnsOnDesktop: number;
  categories: QuestionCategoryQuestionLinkedDocument[];
  merged: { [key: string]: QuestionCategoryDocument };
};

const variants = {
  out: { opacity: 0, y: "100%" },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: [0.23, 1, 0.32, 1],
      duration: 2,
    },
  },
};

export default function QuestionCategoryGrid(props: QuestionCategoryGridProps) {
  const {
    spacingTop = 0,
    spacingBottom = 6,
    highlightColor,
    color,
    backgroundColor,
    animateIn = false,

    maximumQuestions = 5,
    columnsOnMobile = 1,
    columnsOnDesktop = 3,
    categories = [],
    merged = {},

    /*
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor,
    color,

    cards = [],
    columnsOnDesktop = 4,
    columnsOnMobile = 1,
    type = 'a',
    buttonType = 'outlined',
    buttonAction = 'buy',
    animateIn = false,
    skipCrossSells = false,
    showText = true
    */
  } = props;

  // console.log('MERGED', merged);

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  let animate = inView ? "in" : "out";
  if (!animateIn) {
    animate = "in";
  }

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent ref={ref}>
        <motion.div variants={variants} animate={animate}>
          <BlockGrid columnsOnMobile={columnsOnMobile} cols={columnsOnDesktop}>
            {categories.slice(0, maximumQuestions).map((category, i) => (
              <div key={i}>
                <h2>{category.data.title}</h2>
                <SimpleQuestionList>
                  {merged[category.uid]?.content.questions?.map((item, i) => (
                    <li key={i}>
                      <Link
                        href={`/help/${category.uid}/${item.question.uid}`}
                        passHref
                      >
                        <a>{item.question.data.title}</a>
                      </Link>
                    </li>
                  ))}
                </SimpleQuestionList>
                <LinkButton
                  buttonComponent={ButtonArrow}
                  buttonText="View all"
                  href={`/help/${category.uid}`}
                  buttonProps={{ arrow: false }}
                  // buttonProps={{ style: { backgroundColor: 'red' } }}
                  // style={{ maxWidth: '12rem', margin: '0 auto' }}
                />
              </div>
            ))}
          </BlockGrid>
        </motion.div>
      </BlockContent>
    </Block>
  );
}

import QuestionCategoryGrid from "~/components/QuestionCategoryGrid";
import { getCommonSliceProps } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

function mapSliceToProps(slice) {
  const { primary, items, merged } = slice;

  // console.log('ITEMS', items);
  // console.log('MERGED', merged);

  const props = {
    ...getCommonSliceProps(slice),
    maximumQuestions: primary.maximum_questions || 5,
    columnsOnDesktop: primary.columns_on_desktop || 3,
    columnsOnMobile: primary.columns_on_mobile || 1,
    categories: items.map((item) => item.question_category),
    merged,
  };

  return props;
}

export default connectSlice(mapSliceToProps)(QuestionCategoryGrid);

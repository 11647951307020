import styled from "styled-components";
import theme from "~/styles/theme";

export const SimpleQuestionList = styled.ul`
  list-style: none;
  padding-left: 0;
  line-height: 2.2;
  margin-block-end: 1.5em;

  a {
    color: ${theme.colors.dark};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
